<template>
  <div>
    <slot :status="moduleStatus" />
    <b-card v-if="!moduleStatus">
      <b-row class="mb-2 justify-content-end">
        <bulk-actions
          v-if="selectable"
          ref="actions"
          :api-url="apiUrl"
          :add-type="addType"
          :bulk-delete="bulkDelete"
          :go-to-page-or-open-modal="goToPageOrOpenModal"
          :guard="guard"
          :selected="selected"
        >
          <template v-slot:addbtn>
            <b-button
              v-if="addType.length"
              variant="primary"
              @click="goToPageOrOpenModal()"
            >
              <span class="text-nowrap"> {{ addType }} </span>
            </b-button>
          </template>
        </bulk-actions>

        <b-button
          v-if="!selectable && addType.length"
          ref="actions"
          variant="primary"
          class="ml-auto mr-1"
          @click="goToPageOrOpenModal()"
        >
          <span class="text-nowrap"> {{ addType }} </span>
        </b-button>
      </b-row>
      <!-- <b-row class="mb-2">
        <b-col
          v-if="searchBox"
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-model="searchQuery"
            class="d-inline-block"
            placeholder="Search..."
            @input="searchTable"
          />
        </b-col>
        <slot name="filterUsers" />
        <slot name="filterCoupon" />
        <slot name="filterGovernments" />
        <slot name="filterZones" />
        <slot name="filterAds" />

      </b-row> -->
      <b-row>
        <b-col md="12">
          <div
            v-if="dataLoad"
            class=" text-center my-2"
          >
            <b-spinner
              type="grow"
              label="Loading..."
            />
          </div>
          <b-table
            v-else
            ref="productsTable"
            :key="tableDataKey"
            :items="rows"
            :fields="columns"
            :current-page="currentPage"
            :per-page="perPage"
            aria-busy="true"
            class="position-relative"
            responsive
            show-empty
            bordered
            primary-key="id"
            :selectable="selectable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :select-mode="selectMode"
            :no-local-sorting="true"
            @row-selected="onRowSelected"
          >
            <template v-slot:cell(created_at)="value">
              {{ value.item.created_at | formatDate }}
            </template>
            <template v-slot:cell(avatar_url)="value">
              <b-avatar
                size="50"
                :src="value.item.avatar_url"
                class="mb-2"
              />
            </template>
            <template v-slot:cell(image)="value">
              <b-img
                width="70"
                :src="value.item.image"
              /></template>
            <template v-slot:cell(desc_en)="value">
              <div class="desTrim">
                {{ value.item.desc_en }}
              </div>
            </template>
            <template v-slot:cell(desc_ar)="value">
              <div class="desTrim">
                {{ value.item.desc_ar }}
              </div>
            </template>
            <template
              v-for="(_, slotName) of $scopedSlots"
              v-slot:[slotName]="scope"
            >
              <slot
                :name="slotName"
                v-bind="scope"
              />
            </template>
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:head(select)="data">
              <span v-if="selected.length < 1"> {{ data.field.label }} </span>
              <b-form-checkbox
                v-else
                class="custom-control-primary"
                @change="selectAllRows($event)"
              />
            </template>
            <template #cell(select)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <b-form-checkbox
                  class="custom-control-primary"
                  disabled
                />
              </template>
            </template>
            <template #cell(actions)="data">
              <slot
                name="actions"
                v-bind="data"
              >
                <div class="padd">
                  <span
                    v-if="viewContent"
                    title="View"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="viewPage(data.item)"
                    />
                  </span>
                  <span
                    v-if="editContent"
                    title="Edit"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer"
                      @click="editToPage(data.item.id)"
                    />
                  </span>
                  <span
                    v-if="blockContent && data.item.status != 'Blocked'"
                    title="Block"
                  >
                    <feather-icon
                      icon="UserMinusIcon"
                      class="cursor-pointer"
                      @click="viewmodel(data.item.id, 1)"
                    />
                  </span>
                  <span
                    v-if="data.item.status == 'Blocked'"
                    title="UnBlock"
                  >
                    <feather-icon
                      icon="UserPlusIcon"
                      class="cursor-pointer"
                      @click="viewmodel(data.item.id, 0)"
                    />
                  </span>
                  <span
                    v-if="deleteContent"
                    title="Delete"
                  >
                    <feather-icon
                      id="popover-button-1"
                      icon="TrashIcon"
                      class="cursor-pointer"
                      @click="deleteRow(data.item.id)"
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(info)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="showInfo(data.item.department)"
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(actions-view)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'certificate',
                          params: { MCR_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(sections)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'sections',
                          params: { SE_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>
            <template #cell(components)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'components',
                          params: { CM_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(requirements)="data">
              <slot
                name="info"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'requirements',
                          params: { RQ_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(company)="data">
              <slot
                name="company"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="showCompany(data.item.company)"
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(showCertificationCompanies)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'representatives',
                          params: { R_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(showTechnicalExperts)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'technical-experts',
                          params: { T_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(product)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'show-product',
                          params: { P_id: data.item.product.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(request_histories)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      style="color: #297dd6;"
                      @click="
                        $router.push({
                          name: 'request-histories',
                          params: { RH_id: data.item.id }
                        })
                      "
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(assign-manager)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <b-button
                      style="background-color:#297dd6 !important ; border:1px solid #297dd6 !important ;"
                      @click="
                        $router.push({
                          name: 'assign_manager',
                          params: { RAM_id: data.item.id }
                        })
                      "
                    >Assign Manager</b-button>
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(assign-reviewer)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="View">
                    <b-button
                      style="background-color:#297dd6 !important ; border:1px solid #297dd6 !important ;"
                      @click="
                        $router.push({
                          name: 'assign_reviewer',
                          params: { RAR_id: data.item.id }
                        })
                      "
                    >Assign Reviewer</b-button>
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(file)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="Download">
                    <a
                      :href="data.item.file.url"
                      target="_blank"
                    >
                      <feather-icon
                        icon="DownloadCloudIcon"
                        class="cursor-pointer"
                      />
                    </a>
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template
                  v-if="data.item.profile_picture"
                  #aside
                >
                  <b-avatar
                    size="40"
                    :text="avatarText(data.item.first_name)"
                    :src="data.item.profile_picture"
                  />
                </template>
                <template
                  v-else-if="data.item.logo"
                  #aside
                >
                  <b-avatar
                    size="32"
                    :src="data.item.logo"
                  />
                </template>
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  @click="viewPage(data.item)"
                >
                  {{ data.item.name }}
                </b-link>
                <small
                  v-if="data.item.username"
                  class="text-muted"
                >@{{ data.item.username }}</small>
                <small
                  v-if="data.item.name_ar"
                  class="text-muted"
                >{{
                  data.item.name_ar
                }}</small>
                <small
                  v-if="data.item.national_id"
                  class="text-muted"
                >{{
                  data.item.national_id
                }}</small>
              </b-media>
            </template>
            <template #cell(activation_at)="data">
              {{ data.item.activation_at.slice(0, 10) }}
            </template>
            <template #cell(is_active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.is_active === true ? 'success' : 'danger'"
                @click="changeSchangeActivetatus(data.item)"
              >
                {{ resolveActiveUser(data.item.is_active) }}
              </b-badge>
            </template>

            <template #cell(active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.active === 1 ? 'success' : 'danger'"
                style="cursor: auto;"
              >
                {{ resolveActiveUser(data.item.active) }}
              </b-badge>
            </template>

            <template #cell(document)="data">
              <slot
                name="show"
                v-bind="data"
              >
                <div class="text-center">
                  <span title="Download">
                    <a
                      :href="data.item.doc.url"
                      target="_blank"
                    >
                      <feather-icon
                        icon="DownloadCloudIcon"
                        class="cursor-pointer"
                      />
                    </a>
                  </span>
                </div>
              </slot>
            </template>

            <!-- <template #cell(active)="data">
              <b-badge
                class="change-status"
                :variant="data.item.active == 0 ? 'success' : 'danger'"
                @click="changeStatus(data.item)"
              >
                {{ resolveActiveUser(data.item.active) }}
              </b-badge>
            </template> -->
            <!-- <template #cell(status)="data">
              <b-badge :variant="data.item.status == 'true' ? 'success' : 'danger'">
                {{ resolveActiveOrFalse(data.item.status) }}
              </b-badge>
            </template> -->
            <!-- <template #cell(role)="data">
              {{ resolveJobStatus(data.item.role) }}
            </template> -->
          </b-table>

          <!-- //////////////////////////// show popup for DEPARTMENT //////////////////////////// -->

          <div
            v-if="showInfoPopup"
            class="show-info-popup"
          >
            <h3 style="text-align: center; margin: 4%; font-size: 30px;">
              DEPARTMENT
            </h3>

            <div class="show-info-data">
              <p><span> ID : </span> {{ infoId }}</p>
              <p><span>Description : </span> {{ infoDescription }}</p>
              <p><span>Description_Ar : </span> {{ infoDescriptionAr }}</p>
              <p><span>Description_En : </span> {{ infoDescriptionEn }}</p>
              <p><span>Title : </span> {{ infoTitle }}</p>
              <p><span>Title_Ar : </span> {{ infoTitleAr }}</p>
              <p><span>Title_En : </span> {{ infoTitleEn }}</p>
            </div>
            <feather-icon
              icon="XCircleIcon"
              size="30"
              style="cursor: pointer; color: black;position: absolute; top:10px; right: 10px;"
              @click="showInfoPopup = false"
            />
          </div>
        </b-col>

        <!-- //////////////////////////// pagination //////////////////////////// -->
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getAllDataPerPage"
            />
            <label>entries</label>
          </b-col> -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-between "
          >
            <div>
              Results: <span class="font-weight-bold">{{ totalRows }}</span>
            </div>
            <!-- pagination -->
            <b-pagination
              :value="page"
              :total-rows="totalRows"
              :per-page="perPage"
              align="end"
              first-number
              last-number
              :total-visible="7"
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="getAllData($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleFormData from '@/global-compositions/handleFormData'
import BulkActions from './BulkActions.vue'
import table from './table'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    BulkActions,
  },
  props: {
    apiUrl: {
      type: String,
      default: () => '',
    },
    addType: {
      type: String,
      default: () => '',
    },
    addComponentName: {
      type: String,
      default: () => '',
    },
    editComponent: {
      type: String,
      default: () => '',
    },
    viwComponent: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    typeModel: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    viewContent: {
      type: Boolean,
      default: () => true,
    },
    editContent: {
      type: Boolean,
      default: () => true,
    },
    deleteContent: {
      type: Boolean,
      default: () => true,
    },
    blockContent: {
      type: Boolean,
      default: () => true,
    },

    searchBox: {
      type: Boolean,
      default: () => true,
    },
    selectable: {
      type: Boolean,
      default: () => true,
    },
    guard: {
      type: String,
      default: () => '',
    },
    urlType: {
      type: String,
      default: () => '',
    },
    role: {
      type: [String, Number],
      default: () => '',
    },
    exportTable: {
      type: Boolean,
      default: () => true,
    },
    // perPage: {
    //   type: [String, Number],
    //   default: () => '',
    // },
  },
  data() {
    return {
      rows: [],
      totalRows: null,
      isBusy: false,
      currentPage: 1,
      popoverShow: false,
      loader: false,
      currentTime: '',
      showEmpty: 'loading...',
      modes: ['multi', 'single', 'range'],
      selectMode: 'multi',
      selected: [],
      page: 1,
      sortBy: '',
      dataKey: '',
      sortDesc: '',
      loadExport: false,
      dataLoad: 'false',
      tableDataKey: 0,
      showInfoPopup: false,
      showCompanyPopup: false,
      showProductPopup: false,
      infoDescription: '',
      infoDescriptionAr: '',
      infoDescriptionEn: '',
      infoId: '',
      infoTitle: '',
      infoTitleAr: '',
      infoTitleEn: '',
      CompanyInfoDescription: '',
      CompanyInfoId: '',
      CompanyInfoName: '',
      CompanyInfoEmail: '',
      CompanyInfoPhone: '',
      selectedSearchType: this.$route.query.type,
      searchCodeQuery: '',
      perPage: 20,
      searchQuery: this.$route.query.search,
      exportedData: [],
    }
  },
  setup() {
    const { moduleStatus } = table()
    const resolveActiveUser = role => {
      if (role === 1) return 'Active'

      return 'Not Active'
    }
    const { formData, setFormData } = handleFormData()
    const { skin } = useAppConfig()
    const resolveActive = role => {
      if (role === '1') return 'Active'

      return 'Not Active'
    }
    const resolveJobStatus = role => {
      if (role === 0) return 'Admin'
      if (role === 1) return 'Merchant'

      return 'Customer'
    }

    const resolveActiveOrFalse = role => {
      // eslint-disable-next-line eqeqeq
      if (role == 'true') return 'Active'

      return 'Not Active'
    }

    const perPageOptions = [10, 25, 50, 100]

    return {
      resolveActiveUser,
      resolveActive,
      resolveActiveOrFalse,
      resolveJobStatus,
      skin,
      formData,
      setFormData,
      avatarText,
      perPageOptions,
      moduleStatus,
    }
  },
  mounted() {
    this.getAllData(this.$route.query?.page)
    this.getRouting()
    this.getCurrentDateTime()
  },
  methods: {
    exportToPDF() {
      const self = this
      this.loadExport = true
      const promis = axios.get(this.apiUrl, {
        params: { limit: self.perPage, export: 'pdf' },
        responseType: 'arraybuffer',
      })
      return promis
        .then(res => {
          if (res.status === 200) {
            const blob = new Blob([res.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'exported.pdf'
            link.click()
          }
        })
        .finally(() => {
          this.loadExport = false
        })
    },
    exportToCSV() {
      this.loadExport = true
      const self = this
      const promis = axios.get(this.apiUrl, {
        params: { limit: self.perPage, export: 'csv' },
        responseType: 'arraybuffer',
      })
      return promis
        .then(res => {
          if (res.status === 200) {
            const blob = new Blob([res.data], { type: 'application/csv' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'exported.csv'
            link.click()
          }
        })
        .finally(() => {
          this.loadExport = false
        })
    },
    onRowSelected(items) {
      const rows = []
      // eslint-disable-next-line array-callback-return
      items.map(item => {
        rows.push(item.id)
      })
      this.selected = rows
    },
    selectAllRows(rows) {
      if (rows) {
        this.$refs.productsTable.selectAllRows()
      } else {
        this.$refs.productsTable.clearSelected()
      }
    },
    onClose() {
      this.popoverShow = false
    },
    showPopover() {
      this.popoverShow = true
    },
    changeStatus(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          if (data.active === false) {
            this.setFormData(data)
            this.formData.append('activation_at', this.currentTime)
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal('Changed!', 'Changed Successfully.', 'success')
                this.getAllData()
              }
            })
          } else {
            this.setFormData(data)
            this.formData.append('activation_at', '')
            this.formData.append('_method', 'PUT')
            axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
              if (res.status === 200) {
                this.$swal('Changed!', 'Changed Successfully.', 'success')
                this.getAllData()
              }
            })
          }
        }
      })
    },
    changeActivetatus(data) {
      this.$swal({
        title: 'Are you sure that you want to change status?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Change it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.setFormData(data)
          const newStatus = data.active === 0 ? 1 : 0
          this.formData.append('active', newStatus)
          if (data.expire_at) {
            this.formData.append('expire_at', data.expire_at.slice(0, 10))
          }
          this.formData.append('_method', 'PUT')
          axios.post(`${this.apiUrl}/${data.id}`, this.formData).then(res => {
            if (res.status === 200) {
              this.$swal('Changed!', 'Changed Successfully.', 'success')
              this.getAllData()
            }
          })
        }
      })
    },
    editToPage(id) {
      if (this.type === 'page') {
        this.$router.push({ name: this.editComponent, params: { id } })
      } else {
        this.$parent.$refs.genmodal.show(id)
        this.$store.commit('generalIds/SET_ID', id)
      }
    },
    viewmodel(id, isBlocked) {
      if (isBlocked === 1) {
        this.$parent.$refs['block-modal'].show(id)
      } else {
        this.$parent.$refs['unblock-modal'].show(id)
      }
      this.$store.commit('generalIds/SET_ID', id)
    },
    viewPage(data) {
      if (this.viwComponent) {
        this.$router.push({ name: this.viwComponent, params: { id: data.id } })
        this.$store.commit('generalIds/SET_ID', data.id)
      } else {
        this.$parent.$refs['view-modal'].show()
        this.$store.commit('generalIds/SET_ID', data.id)
      }
    },
    goToPageOrOpenModal() {
      if (this.type === 'page') {
        this.$router.push({ name: this.addComponentName })
      } else {
        this.$parent.$refs.genmodal.show()
      }
    },
    roleType() {
      if (this.$route.name === 'users') {
        return '0'
      }
      if (this.$route.name === 'merchant-users') {
        return '1'
      }
      if (this.$route.name === 'customer-users') {
        return '2'
      }
      return ''
    },
    getAllData(page) {
      this.dataLoad = true
      let skip = 0
      if (page !== 1 && page !== undefined) {
        skip = page * 20 - 20
      } else {
        skip = 0
      }
      let params
      if (this.apiUrl.includes('users')) {
        params = {
          skip,
          search: this.searchQuery || this.$route.query.search,
          type:
            this.searchType === true
            && (this.searchQuery !== '' || this.$route.query.search !== '')
              ? this.$route.query.type
              : null,
        }
      } else {
        params = {
          skip,
          search: this.searchQuery || this.$route.query.search,
        }
      }

      const promis = axios.get(this.apiUrl, {
        params,
      })
      return promis
        .then(res => {
          if (res.status === 200) {
            const responseData = decryptData(res.data.payload)
            // console.log(responseData)

            const items = responseData?.data[Object.keys(responseData?.data)[0]]
            this.rows = items

            this.totalRows = responseData.data.count
            this.perPage = 20
            this.$router
              .push({ query: { ...this.$route.query, page } })
              .catch(() => {})
            this.page = page
            if (this.exportData) {
              this.getAllDataToExport()
            }
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server Error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.dataLoad = false
          this.loader = false
        })
    },
    deleteRow(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(`${this.apiUrl}/${id}`)
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    async searchTable() {
      const response = await axios.get(
        `${this.apiUrl}?search=${this.searchQuery}&page=${this.$route.query
          ?.page || 1}`,
      )
      this.$router
        .push({ query: { ...this.$route.query, search: this.searchQuery } })
        .catch(() => {})
      this.rows = response.data?.data
      this.totalRows = response.data.count
      this.perPage = 20
      this.page = response.data.meta?.per_page
    },
    getAllDataPerPage(perpage) {
      const self = this
      const promis = axios.get(this.apiUrl, {
        params: {
          limit: perpage,
          role: self.roleType(),
        },
      })
      return promis.then(res => {
        if (res.status === 200) {
          const items = res.data?.data
          this.rows = items
          this.totalRows = res.data.data.count
          this.perPage = res.data.meta?.per_page
        }
      })
    },
    getRouting() {
      if (this.$route.query.search) {
        this.searchQuery = this.$route.query?.search
        this.searchTable()
      }
    },
    getCurrentDateTime() {
      const today = new Date()

      const date = `${today.getFullYear()}-${today.getMonth()
        + 1}-${today.getDate()}`

      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

      const dateTime = `${date} ${time}`

      this.currentTime = dateTime
    },
    bulkDelete() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .post(`${this.apiUrl}/bulk-destroy`, {
              ids: this.selected,
            })
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    sortData(e) {
      const self = this
      const sortType = () => {
        if (e.sortDesc) return 'DESC'
        return 'ASC'
      }
      const promis = axios.get(this.apiUrl, {
        params: {
          limit: self.perPage,
          search: self.searchQuery || self.$route.query.search,
          sort_colum: e.sortBy,
          sort_type: sortType(),
        },
      })
      return promis
        .then(res => {
          if (res.status === 200) {
            const items = res.data?.data
            this.rows = items
            this.totalRows = res.data.data.count
            this.perPage = res.data.meta?.per_page
          }
        })
        .catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server Error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
    showInfo(data) {
      this.showInfoPopup = true
      this.infoDescription = data.description
      this.infoDescriptionAr = data.description_ar
      this.infoDescriptionEn = data.description_en
      this.infoId = data.id
      this.infoTitle = data.title
      this.infoTitleAr = data.title_ar
      this.infoTitleEn = data.title_en
    },
    showCompany(data) {
      this.showCompanyPopup = true
      this.CompanyInfoDescription = data.description
      this.CompanyInfoId = data.id
      this.CompanyInfoName = data.name
      this.CompanyInfoEmail = data.email
      this.CompanyInfoPhone = data.phone
    },
  },
}
</script>

<style lang="scss">
.b-table tbody tr:hover {
  background-color: #297dd6;
  color: white;
}
.padd span {
  padding: 2px;
}
td {
  padding: 0px !important;
  text-align: center !important;
}
#table-spinner {
  position: absolute;
  right: 50%;
  top: 5rem;
  z-index: 999999999;
}
.change-status {
  cursor: pointer;
}
.show-info-popup {
  background-color: rgb(229, 228, 228);
  color: white;
  border-radius: 10px;
  min-height: 400px;
  width: 600px;
  position: absolute;
  top: -100px;
  left: 370px;
  z-index: 99999999999;
  .show-info-data {
    margin: 5%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    p {
      color: #6d6a7aff;
      font-size: large;
      span {
        color: rgb(16, 15, 18);
        font-size: x-large;
      }
    }
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
